/**
 * Campaign Partner Pixels Configuration module
 * @module apps/multi-step-app/brands/can/pixel-config.js
 *
 * Structure:
 *      The pixel config contains pixels data for each specific campaign partner. To add a
 *      new campaign partner pixel to this config, the structure is as follows:
 *
 *          [campaignId]: {
 *              // default settings applied to all lifecycle events for this campaign can be added at this level
 *              lifecycle: {
 *                  onEntry: {
 *                      // settings for lifecycle-specfic events can be added here
 *                      position: "head" | "body",
 *                      entriesList: [
 *                          {
 *                              position: "head" | "body",
 *                              code: string | function,
 *                          },
 *                      ],
 *                  },
 *                  onRouteChange: {
 *                      entriesList: [
 *                          action: () => {},
 *                      ],
 *                  },
 *                  postSubmit: {
 *                      // settings for lifecycle-specfic events can be added here
 *                      position: "head" | "body",
 *                      entriesList: [
 *                          {
 *                              code: string | function,
 *                              hasDocumentWriteFn: boolean,
 *                              postback: object | boolean,
 *                          },
 *                      ],
 *                  }
 *                  [some_lifecycle_event]: object | boolean,
 *              },
 *          }
 *
 * Property values:
 *      campaignId {number}             - the campaign id associated with the campaign partner.
 *                                        `campaignId: 0` is a global entry, for pixels that should be loaded regardless of
 *                                        campaign (i.e Facebook Pixel, Snapchat Pixel, Twitter Pixel, etc).
 *
 *      lifecycle {object}              - contains the lifecycle events the pixel can have
 *
 *      onEntry {boolean || object}     - tells the CampaignHandler whether or not a pixel needs to be
 *                                        injected at the beginning of the app. If a pixel is NOT required,
 *                                        then a value of `false` will suffice. If a pixel IS required to
 *                                        be injected, then include in the `onEntry` object a `code`
 *                                        property that defines the html pixel to be injected.
 *
 *      onRouteChange {object}          - a lifecycle event for route changes in the app, execute some action (i.e call a function)
 *
 *      postSubmit {boolean || object}  - similar to the onEntry event, this tells the CampaignHandler whether or not to
 *                                        fire some sort of post-submit logic or inject some code into the DOM. The shape
 *                                        and values of this object is also similar to onEntry, where a `code` and `position`
 *                                        property can also be defined.
 *
 *      entriesList {array}        - a list of pixel entries where each entry represents a pixel code and postback if applicable
 *
 *      position {string}               - the position of where the pixel should be injected into the DOM.
 *
 *      code {string || function}       - defines the html pixel code to be injected into the DOM. This property's
 *                                        value can be a template literal (string) for static html snippets or a
 *                                        function for dynamic html snippets.
 *
 *      hasDocumentWriteFn {boolean}    - found/defined within a lifecycle event. This is to guard against unexpected behaviours
 *                                        if 'document.write' is known to be executed. If 'true', 'document.write' is replaced
 *                                        temporarily before being restored again after its invocation.
 *
 *      postback {boolean || object}    - a property to indicate that a request is required to be sent to some partner API for
 *                                        postback purposes, usually to approve/decline transactions. Within this property, there
 *                                        contains an `apiUrl` and `payload` properties that's for the target partner API configuration.
 *
 *      url {string}                    - the postback API url
 *
 *      payload {object || function}    - similar to the `code` property, this defines the payload for the postback request.
 *
 *      httpMethod {string}             - the http method for the postback request
 *
 *      headers {object}                - the headers for the postback request
 *
 *      * In any of the lifecycle events where code is needed to be injected (i.e onEntry, postSubmit), the default
 *        `position` property can be overridden by defining a `position` property within the lifecycle event itself.
 *        If a position is defined within the lifecycle event, that position will be used upon injection. Otherwise,
 *        the default position defined will be used.
 *
 * Method signatures:
 *      code(_, <param_to_use>) => string | payload(_, <param_to_use>) => object
 *          * the stage/event name {string} is passed into first argument
 *          * the campaignData fetched from the app is passed into the second argument of this
 *            method so that any property within campaignData can be used for a dynamic pixel snippet.
 *
 * Caveats:
 *      `code` property:
 *          * CSP (Content Security Policy) - New pixels being added and used in the config need to be verified in a
 *                                            production build run. CSP policies can cause the pixels to fail, thus it
 *                                            is important that the source domains/subdomains of the pixels are being added
 *                                            to the CSP directives in `gatsby-config.js` under the CLC Loan Application
 *                                            (path: /apps/clc-loan-application).
 *
 *          * Code retrieved from the `code` property MUST be valid HTML or the injection will fail.
 *
 *          * The code defined must be simple html. Since the html code is being added into the DOM via `innerHTML`, this
 *            means any embedded tags inside another tag will not be executed. i.e For tags such as <script>, they must be
 *            top-level in order for it to be fired properly.
 */

import { facebook, gtm, snapchat, tiktok, twitter } from "../../src/utils/pixels";

export default {
    0: { // global
        lifecycle: {
            onEntry: {
                position: "head",
                entriesList: [
                    { // Facebook Pixel
                        // CLC (649739105625403)
                        position: "body",
                        code: facebook(["649739105625403"]),
                    },
                    { // Twitter Pixel
                        code: twitter(["o4cnq"]),
                    },
                    { // TikTok Pixel
                        // CLC (C0EMODTJE9B8D1KPN100)
                        // CA2JQ4RC77UAH7NCF3C0
                        code: tiktok(["C0EMODTJE9B8D1KPN100", "CA2JQ4RC77UAH7NCF3C0"]),
                    },

                    { // Google Pixel
                        code: gtm(["AW-837130465"]),
                    },
                ],
            },
            onRouteChange: {
                entriesList: [
                    { // Facebook Pixel
                        action: (_, leadMeta, opts) => {
                            if (window.fbq) {
                                window.fbq("track", "PageView");
                                if (opts.isIndexPage) {
                                    window.fbq("trackSingle", "936171180264390", "ViewContent");
                                }
                            }
                        },
                    },
                    { // Twitter Pixel
                        action: () => {
                            if (window.twq) window.twq("track", "PageView");
                        },
                    },
                    { // TikTok Pixel
                        action: () => {
                            if (window.ttq) window.ttq.track("Browse");
                        },
                    },
                ],
            },
            postSubmit: {
                position: "body",
                entriesList: [
                    { // TikTok Pixel
                        action: () => {
                            if (window.ttq) {
                                window.ttq.track("Purchase");
                            }
                        },
                    },
                    { // Gtag
                        action: () => {
                            if (window.gtag) {
                                window.gtag("event", "conversion", {"send_to": "AW-648789973/th5PCJnckdQBENX_rrUC"});
                                window.gtag("event", "conversion", {
                                    "send_to": "AW-837130465/1tijCLD1zNYDEOGxlo8D",
                                    "value": 50.0,
                                    "currency": "CAD",
                                });
                            }
                        },
                    },
                ],
            },
        },
    },
    207: { // SMB
        lifecycle: {
            onEntry: {
                position: "head",
                entriesList: [
                    { // Facebook Pixel
                        /* SMB (
                            818911122169193,
                            3988027524577500,
                            839615839979212,
                            514374793316153,
                            936171180264390,
                            355186992746922,
                            1972336179609907,
                            1175694786546259,
                            1390514394749154, // <-- This one is conversions only
                           )
                        */
                        position: "body",
                        code: facebook([
                            "818911122169193",
                            "3988027524577500",
                            "839615839979212",
                            "514374793316153",
                            "936171180264390",
                            "355186992746922",
                            "1972336179609907",
                            "1175694786546259",
                        ]),
                    },
                    { // Snapchat Pixel
                        // SMB (40c35225-9f3c-421f-9062-864877a847fc)
                        // SMB (a9e03b61-21f0-466c-8015-f13835ad71fc)
                        // SMB (6548818f-244c-40d2-8603-161ac29bbaea)
                        code: snapchat(["40c35225-9f3c-421f-9062-864877a847fc", "a9e03b61-21f0-466c-8015-f13835ad71fc", "6548818f-244c-40d2-8603-161ac29bbaea"]),
                    },
                    { // TikTok Pixel
                        /* SMB (
                            C1QACUEHLSU5AAHCLS10,
                            C1QV5KUHLSU5AAHCO47G,
                            C2BV847MU8Q03RAINR9G,
                            C33RP3NG09F7S5THP4VG,
                            C5NN5V5O3VNUQLVLFGT0,
                            C80OAI6VLJR68Q6COJC0,
                            C9SSSPJC77U007ITBQVG
                           )
                        */
                        code: tiktok([
                            "C1QACUEHLSU5AAHCLS10",
                            "C1QV5KUHLSU5AAHCO47G",
                            "C2BV847MU8Q03RAINR9G",
                            "C33RP3NG09F7S5THP4VG",
                            "C5NN5V5O3VNUQLVLFGT0",
                            "C80OAI6VLJR68Q6COJC0",
                            "C9SSSPJC77U007ITBQVG",
                        ]),
                    },
                    { // Gtag
                        /* SMB (AW-648789973),
                        */
                        code: `
                            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-648789973"></script>
                            <script>
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'AW-648789973');
                            </script>
                        `,
                    },
                ],
            },
            onRouteChange: {
                entriesList: [
                    { // Facebook Pixel
                        action: (_, leadMeta, opts) => {
                            if (window.fbq) {
                                window.fbq("track", "PageView");
                                if (opts.isIndexPage) {
                                    window.fbq("trackSingle", "936171180264390", "ViewContent");
                                }
                            }
                        },
                    },
                    { // Snapchat Pixel
                        action: () => {
                            if (window.snaptr) window.snaptr("track", "PAGE_VIEW");
                        },
                    },
                    { // Twitter Pixel
                        action: () => {
                            if (window.twq) window.twq("track", "PageView");
                        },
                    },
                    { // TikTok Pixel
                        action: () => {
                            if (window.ttq) window.ttq.track("Browse");
                        },
                    },
                ],
            },
            postSubmit: {
                position: "body",
                entriesList: [
                    { // SMB Conversion Pixel
                        code: `<script>
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '1390514394749154');
                            fbq('trackSingle', '1390514394749154', 'Contact', {
                                value: 50.00,
                                currency: 'USD'
                            });
                        </script>`,
                    },
                    { // Facebook Pixel
                        action: (_, leadMeta, opts) => {
                            if (window.fbq) {
                                window.fbq("track", "Purchase");
                            }
                        },
                    },
                    { // Snapchat Pixel
                        action: () => {
                            if (window.snaptr) window.snaptr("track", "SIGN_UP");
                        },
                    },
                    { // TikTok Pixel
                        action: () => {
                            if (window.ttq) {
                                window.ttq.track("SubmitForm");
                            }
                        },
                    },
                    { // Gtag
                        action: () => {
                            if (window.gtag) {
                                window.gtag("event", "conversion", {"send_to": "AW-648789973/th5PCJnckdQBENX_rrUC"});
                            }
                        },
                    },
                ],
            },
        },
    },
    208: { // Car Gurus
        lifecycle: {
            onEntry: {
                position: "head",
                entriesList: [
                    { // Facebook Pixel
                        // Gurus (3184431341835967)
                        position: "body",
                        code: facebook(["3184431341835967"]),
                    },
                ],
            },
            onRouteChange: {
                entriesList: [
                    { // Facebook Pixel
                        action: (_, leadMeta, opts) => {
                            if (window.fbq) {
                                window.fbq("track", "PageView");
                            }
                        },
                    },
                ],
            },
            postSubmit: {
                position: "body",
                entriesList: [
                    { // Facebook Pixel
                        action: (_, leadMeta, opts) => {
                            if (window.fbq) {
                                window.fbq("track", "Lead");
                            }
                        },
                    },
                ],
            },
        },
    },
};
